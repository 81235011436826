{
  "name": "stream-frontend-web-gp-workspace",
  "version": "2.148.1-9fe00b3-110",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --open",
    "lint": "tsc --noEmit && eslint . --ext js,ts,json --quiet --fix",
    "build": "ng build --build-optimizer",
    "watch": "ng build --watch --configuration development",
    "test": "node --max-old-space-size=2048 ./node_modules/.bin/ng test --code-coverage --no-watch --no-progress --browsers=ChromeHeadlessCI",
    "prepare": "husky install",
    "s": "node ./scripts/start-smart && yarn start",
    "libs": "node ./scripts/libs"
  },
  "private": true,
  "config": {
    "commitizen": {
      "path": "node_modules/cz-customizable"
    },
    "cz-customizable": {
      "config": "cz-config.js"
    }
  },
  "dependencies": {
    "@angular/animations": "17.3.12",
    "@angular/cdk": "17.3.10",
    "@angular/common": "17.3.12",
    "@angular/compiler": "17.3.12",
    "@angular/core": "17.3.12",
    "@angular/forms": "17.3.12",
    "@angular/localize": "17.3.12",
    "@angular/material": "17.3.10",
    "@angular/material-moment-adapter": "17.3.10",
    "@angular/platform-browser": "17.3.12",
    "@angular/platform-browser-dynamic": "17.3.12",
    "@angular/router": "17.3.12",
    "@ant-design/icons-angular": "^17.0.0",
    "@bitestream/countries": "^1.0.0",
    "@ctrl/ngx-codemirror": "^7.0.0",
    "@fingerprintjs/fingerprintjs": "^3.3.0",
    "@sentry/angular-ivy": "^7.98.0",
    "angular-code-input": "^2.0.0",
    "codemirror": "5.65.17",
    "localstorage-polyfill": "^1.0.1",
    "lodash": "^4.17.21",
    "mock-browser": "^0.92.14",
    "moment": "^2.18.1",
    "moment-timezone": "^0.5.44",
    "ng-recaptcha": "^13.2.1",
    "ng-zorro-antd": "^17.4.1",
    "ngx-clipboard": "^15.0.1",
    "ngx-webstorage": "13.0.1",
    "rxjs": "^7.5.5",
    "tiny-pinyin": "^1.3.2",
    "tslib": "^2.3.1",
    "uuid": "^8.3.2",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.11",
    "@angular-devkit/core": "^17.3.11",
    "@angular-eslint/eslint-plugin": "^17.5.3",
    "@angular-eslint/eslint-plugin-template": "^17.5.3",
    "@angular-eslint/template-parser": "^17.5.3",
    "@angular/cli": "17.3.11",
    "@angular/compiler-cli": "17.3.12",
    "@angular/language-service": "17.3.12",
    "@types/jasmine": "^4.3.1",
    "@types/lodash": "^4.14.199",
    "@types/node": "18.19.12",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "autoprefixer": "^10.4.2",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "8.1.0",
    "husky": "^8.0.0",
    "inquirer": "^8.2.4",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "karma-spec-reporter": "0.0.36",
    "lint-staged": "^12.4.1",
    "postcss": "^8.4.18",
    "postcss-import": "^15.0.0",
    "prettier": "^2.6.2",
    "prettier-plugin-tailwindcss": "^0.1.13",
    "tailwindcss": "3.4.1",
    "typescript": "5.4.5"
  },
  "lint-staged": {
    "*.{ts,html}": "eslint --fix",
    "*.{ts,html,less,scss,css}": "prettier --write"
  }
}
